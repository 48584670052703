

































































import { CreateUserDto, UserRole, UserRoleOption, UserStatus } from '@/api/user/model'
import { computed, defineComponent, reactive, Ref, ref, set } from '@vue/composition-api'
import LayoutHeader from '@/components/LayoutHeader.vue'
import { Form, FormItem, Tag } from 'element-ui'
import { roleOptions } from '@/api/user/role-option'
import { createUserApi, getMemberListApi, updateUserApi, resentUserApi } from '@/api/user'
import { useUserStore } from '@/pinia/modules/user'
import { storeToRefs } from 'pinia'
import { showError } from '@/utils/common'
import { useVerifyCode } from '@/hooks/useVerifyCode'

export default defineComponent({
    components: {
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Tag.name]: Tag,
        LayoutHeader
    },
    setup(props, { root }) {
        const { verifyCodeObj, setVerifyCodeObj, startSendVerifyCode } = useVerifyCode()
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)
        const roleOptionList: Ref<UserRoleOption[]> = ref([])
        if (userInfo.value.role === UserRole.Super) {
            roleOptionList.value = roleOptions.filter((v) => ![UserRole.Super].includes(v.value))
        } else if (userInfo.value.role === UserRole.Admin) {
            roleOptionList.value = roleOptions.filter((v) => ![UserRole.Super, UserRole.Admin].includes(v.value))
        }

        const userId = computed(() => root.$route.params.userId)
        const detailObj: any = reactive({})

        const formRef = ref()
        const formInfo: CreateUserDto = reactive({
            email: '',
            nickname: '',
            role: UserRole.User
        })
        const formRules = reactive({
            email: [
                { required: true, message: root.$i18n.t('memberEdit.formRule1'), trigger: 'blur' },
                { type: 'email', message: root.$i18n.t('memberEdit.formRule2'), trigger: ['blur', 'change'] }
            ],
            nickname: [{ required: true, message: root.$i18n.t('memberEdit.placeholder2'), trigger: 'blur' }],
            role: [{ required: true, message: root.$i18n.t('memberEdit.placeholder3'), trigger: 'change' }]
        })
        const getUserDetail = async () => {
            if (!userId.value) {
                return
            }
            try {
                const filters: any[] = [['id', userId.value]]
                const { items } = await getMemberListApi({
                    page: 0,
                    size: 1,
                    filters: JSON.stringify(filters)
                })
                if (items.length > 0) {
                    const detailInfo = items[0]
                    Object.keys(detailInfo).forEach((key) => {
                        set(detailObj, key, detailInfo[key])
                    })
                    Object.keys(formInfo).forEach((key) => {
                        formInfo[key] = detailInfo[key]
                    })
                    console.log(formInfo)

                    if (detailObj.status == UserStatus.NotActive) {
                        setVerifyCodeObj({
                            key: `user_active_${userId.value}`,
                            loading: false,
                            counVal: '',
                            countDown: 60
                        })
                    }
                }
            } catch (error) {
                showError(error)
            }
        }
        getUserDetail()

        const submitInfo = reactive({
            isSubmitLoad: false,
            isDisabled: computed(() => {
                return !Object.keys(formRules).every((key) => formInfo[key])
            }),
            onSubmitFrom: () => {
                formRef.value.validate().then(async () => {
                    submitInfo.isSubmitLoad = true
                    try {
                        if (userId.value) {

                            const userParams: any = {
                                nickname: formInfo.nickname,
                                role: formInfo.role!,
                                user_id: String(userId.value)
                            }

                            if (userInfo.value.role !== UserRole.Super) {
                                delete userParams.role
                            }
                            await updateUserApi(userParams)
                            root.$message.success(root.$t('saveSuccess').toString())
                        } else {

                            const id = await createUserApi({ ...formInfo })
                            root.$router.replace({
                                name: 'MemberList'
                            })
                        }
                    } catch (error) {
                        showError(error)
                    }
                    submitInfo.isSubmitLoad = false
                })
            }
        })

        const userActiveInfo = reactive({
            isSubmitLoad: false,
            onSubmit: async () => {
                userActiveInfo.isSubmitLoad = true
                try {
                    await resentUserApi({
                        email: detailObj.email
                    })
                    root.$message.success(<string>root.$t('memberEdit.sendEmailMsg3', { email: formInfo.email }))
                    startSendVerifyCode()
                } catch (error) {
                    showError(error)
                }
                userActiveInfo.isSubmitLoad = false
            }
        })

        return {
            roleOptionList,
            userId,
            formRef,
            formInfo,
            formRules,
            submitInfo,
            detailObj,
            UserStatus,
            verifyCodeObj,
            userActiveInfo
        }
    }
})
