import { UserRole, UserRoleName, UserRoleOption } from './model'

export const roleOptions: UserRoleOption[] = [
    {
        label: UserRoleName[UserRole.Super],
        value: UserRole.Super
    },
    {
        label: UserRoleName[UserRole.Admin],
        value: UserRole.Admin
    },
    {
        label: UserRoleName[UserRole.User],
        value: UserRole.User
    }
]
